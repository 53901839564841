.iframe-embed.w-grid {
    min-width : 300px;
}


@media only screen and (max-width: 600px) {
    .iframe-embed .ui-info-box,
    .iframe-embed .ui-registration-form {
        width: 100%;
    }
    .iframe-embed .ui-info-box {
        margin-bottom: 20px;
    }
}